label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  min-width: 30%;
  padding: 40px 40px;
  position: fixed;
  right: 0;
  margin-right: 50px;
  z-index: 100;
}

.card-container.card button {
  background-color: #214187;
  border-color: #214187;
}

.card {
  background-color: #f7fafd;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.header-bg-color {
  background-color: #f7fafd !important;
}


.header{
  color: #000;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 2px solid #214187;
  z-index: 100;
}

.header .navbar-brand img {
  height: 60px;
}

.aeContainer {
  width: 15%;
  text-align: right;
}

.enpicdaContainer {
  width: 44%;
  text-align: right;
}

.enpicdaContainer a {
  color: #214187;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}

.left{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;
  position: fixed;
  height: 100%;
}

.right{
  min-height:680px;
  display:flex;
  align-items:center;
  justify-content:center;  
  background-position: center;
  background-size: cover;
  padding-right: 0 !important;
  padding-left: 0 !important;    
}

.ml250 {
  margin-left: 250px;
}

.footer{
  text-align:center;
  height: 130px;
  padding: 0;
  border-top: 1px solid #e1e1e1;
  color: #214187;
  width:100%;
  text-align:center;
}

.footerinline {
  background-color: #ebebeb;
}

.footerleft {
  float:left;
  min-width: 30%;
  text-align: left;
  padding-left: 10px;
  line-height: 50px;
  font-size: 14px;
}

.footercenter {
  display: inline-block;
  min-width: 40%;
  vertical-align: middle;
  line-height: 50px;
  text-align: center;
}

.footerright {
   float:right; 
  min-width: 30%;
  text-align: right;
  padding-right: 10px;
  line-height: 50px;
}

.footerright img {
  height: 50px;
}

.footersub {
  background-color: #214187;
  color: #fff;
  text-align: left;
  padding-left: 10px;
  height: 30px;
}

.mainfooter{
  height: 190px;
}

.footertop {
  padding: 10px; 
  border-top: 1px solid #e1e1e1;
  color: #214187;
  background-color: #ebebeb;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.footerbottom {
  background-color: #214187;
  color: #fff;
  text-align: left;
  padding-left: 10px;
  height: 30px;
}

.nav-link {
  color: #214187!important;
}

.navbar {
  padding: 0;
}

.navfont {
  font-size: 26px;
}

.nav-link:focus, 
.nav-link:hover {
  color: #834444!important;
}

.left a {
  color: #214187;
  position: relative;
  
}

.left li {
  list-style: none;
  padding: 5px 10px;
  font-size: 16px;
}

.left li:hover {
  font-weight: bold;
}

.newContainer {
  width: 100vw;
  position: absolute;
  top: 50px;
  /* max-width: 76vw; */
  min-width: 96%;
}

.reportheader {
  background-color: #eef1f4;
}

.jumbotron {
  padding: 1rem 1rem;
  background-color: #f7fafd;
}

.jumbotron .table-wrapper table thead {
  background-color: #214187;
  color: #f7f7f7;
}

.column {
  float: left;
  padding: 10px;

}

.leftc {
  /* width: 25%;
  border: 1px solid #fefefe; */
  display: inline-block;
  width: 100%;
  border: 1px solid #fefefe;
  margin: 0;
  padding: 0;
  line-height: 30px;
  vertical-align: top;
  padding: 5px;
}

.leftc p {
  margin: 0;
}

.rightc p {
  margin: 0;
}

.centerc p {
  margin: 0;
}

.leftc label {
  color: #214187;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.centerc {
  display: inline-block;
  width:100%;
  border: 1px solid #fefefe;
  line-height: 30px;
  vertical-align: top;
  padding: 5px;
}

.centerc label {
  color: #214187;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.rightc {
  /* width: 75%;
  border: 1px solid #fefefe; */
  display: inline-block;
  width: 100%;
  border: 1px solid #fefefe;
  line-height: 30px;
  vertical-align: top;
  padding: 5px;
}

.rightc label {
  color: #214187;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.summaryheader {
  background-color: #f7fafd;
  border-radius: .3rem;
  /* padding: .5em .5em; */
}

.summarydetail {
  margin: 6px;
}

.summaryd {
  background-color: #214187;
  color: #fff;
  padding: 5px;
  font-weight: bold;
  margin-left: 1px;
  margin-right: 1px;
  height: 40px;
  vertical-align:middle;
  font-size: 18px;
}

.outerSummary {
  padding: 0;
    display: inline-block;
    width: 100%;
    min-height: 40px;
}

.summaryInfoBackBtn {
  
  background-color: #214187;
  text-decoration: none;
}

.summaryInfoBackBtn a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.summaryInfoBackBtn a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.summaryInfoBackBtn a:hover {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}

.summaryInfoBackBtn a:active {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}

.awssld__wrapper {
  height: calc(100vh - 120px) !important;
}


.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: absolute;
  background: #000;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.loader-container {
  position: fixed;
  height: 100%;
  background: #7f7b7b9c;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

.flowList {
  display: inline-table;
  width: 15%;
  list-style: outside none none;
  text-align: center;
}

.downloadReports {
  color: #214187;
  position: relative;
  font-weight: bold;
}

.downloadReports:hover {
  font-weight: bold;
}

.fileUploadField {
  font-size: 16px;
  padding: 25px;
  color: #214187;
  font-weight: bold;
}

.simulateBtn {
  color: #fff;
  background-color: #214187;
  border-color: #357ebd; /*set the color you want here*/
  width: 120px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}
.simulateBtn:hover, .simulateBtn:focus, .simulateBtn:active, .simulateBtn.active, .open>.dropdown-toggle.simulateBtn {
  color: #fff;
  background-color: #214187;
  border-color: #285e8e; /*set the color you want here*/
  width: 120px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}