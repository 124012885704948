.table-wrapper {
  position: relative;
}
.table-scroll {
  height: 400px;
  overflow: auto;
  margin-top: 0;
  border-top: 1px solid grey;
}
.table-wrapper table {
  width: 100%;
  margin-top: -1px;
}
.table-wrapper table thead {
  position: sticky;
  top: 0;
  background: white;
}